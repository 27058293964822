.transfer {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 64px;
  background-color: $gray-1;
  border-bottom: 1px solid $gray-4;
  border-left: 1px solid $gray-4;
  border-right: 1px solid $gray-4;
  overflow: hidden;
  cursor: pointer;
  @include Transition;
  & > div {
    @include Transition;
  }
  &.isContinuation {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  &.hasContinuation {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    &:last-child {
      border-bottom: none;
    }
  }
  &:first-child {
    border-top: 1px solid $gray-4;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.in {
    .transfer-crypto {
      background: linear-gradient(270deg, #F8F8F8 35.87%, #FFEEF6 102.91%);
      &:before {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: -23px;
        left: -23px;
        background-color: $gray-1;
      }
      &:after {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: 40px;
        left: -23px;
        background-color: $gray-1;
      }
    }
  }
  &.out {
    .transfer-fiat {
      background: linear-gradient(90deg, #F3F4F9 43.56%, #E0E7FE 102.91%);
      &:before {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: -23px;
        right: -23px;
        background-color: $gray-1;
      }
      &:after {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: 40px;
        right: -23px;
        background-color: $gray-1;
      }
    }
  }
  &:hover {
    background-color: $gray-light;
    &.out .transfer-fiat:before,&.out .transfer-fiat:after,
    &.in .transfer-crypto:before,&.in .transfer-crypto:after, {
      @include Transition;
      background-color: $gray-light;
    }
    .transfer-client {
      background: $gray-2;
    }
  }
  &__cel {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__col {
    display: flex;
    flex-direction: column;
  }
  &__icon {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &-client {
    width: 200px;
    border-right: 1px solid $gray-4;
    display: flex;
    flex-direction: row;
    &-data {
      padding-left: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
    &__full-name {
      max-width: 144px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      @include Ellipsis;
    }
    &__client-id {
      font-size: 12px;
      line-height: 16px;
      color: $gray-7;
      max-width: 144px;
      @include Ellipsis;
    }
    &__risk-level {
      height: 100%;
      width: 16px;
      &.low,&.medium,&.high,&.severe {
        border-right: 1px solid $gray-4;
      }
      &.low {
        background: repeating-linear-gradient(
            45deg,
            $gray-1,
            $gray-1 3px,
            $red-3 3px,
            $red-3 6px
        );
      }
      &.medium {
        background: $red-3;
      }
      &.high {
        background: $red-6;
      }
      &.severe {
        background: $red-7;
      }
    }
  }
  &-operation {
    width: 213px;
    display: flex;
    align-items: center;
    padding: 8px;
    .transfer__icon {
      margin-right: 6px;
    }
    &__subject {
      font-size: 14px;
      line-height: 18px;
    }
    &__occurred-at {
      font-size: 12px;
      line-height: 16px;
      color: $gray-7;
    }
  }
  &-fiat {
    position: relative;
    width: 221px;
    display: flex;
    align-items: center;
    padding: 8px;

    .transfer__icon {
      margin-right: 8px;
    }
    &__amount {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
    &__details {
      margin-left: 11px;
      font-size: 10px;
      line-height: 12px;
      color: $gray-7;
    }
  }
  &-crypto {
    width: 221px;
    display: flex;
    align-items: center;
    padding: 8px;
    padding-left: 37px;
    position: relative;

    .transfer__icon {
      margin-right: 8px;
      margin-top: 2px;
    }
    .crypto-icon {
      width: 24px;
      height: 24px;
    }
    &__wallet {
      position: absolute;
      top: 6px;
      left: 37px;
      font-size: 10px;
      line-height: 12px;
      color: $gray-7;
    }
    &__amount {
      margin-top: 14px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
    &__details {
      @include Ellipsis;
      max-width: 146px;
      font-size: 10px;
      line-height: 12px;
      color: $gray-7;
    }
  }
  &-additional {
    margin-left: auto;
    padding: 20px 16px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__tag {
      background: $gray-4;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      span {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: $gray-8;
        width: 24px;
      }
    }
    &__notes {
      margin-left: 8px;
      circle {
        fill: $gray-4;
      }
      path {
        fill: $gray-8;
      }
    }
  }
}
