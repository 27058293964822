.clients-detail {
  &-main {
    min-height: 618px;
    position: relative;
    padding: 40px;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 8px;

    &-top {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 22px;
      &__title {
        max-width: 800px;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        span {
          @include Ellipsis;
          margin-right: 12px;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
        }
      }
      &__alerts {
        position: absolute;
        top: 32px;
        left: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $pink;
      }
    }
    &-left {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
    }
    &-right {
      margin-top: 15px;
      width: 410px;
    }
    &-id {
      position: absolute;
      top: 40px;
      right: 40px;
      font-size: 14px;
      line-height: 24px;
      color: $gray-7;
    }
    &-wallets {
      margin-top: 32px;
    }
    &-resent-transactions {
      position: relative;
    }
    &-transactions-radio {
      position: absolute;
      top: -10px;
      right: 0;
      margin-bottom: 24px;
      .ant-radio-button-wrapper {
        color: $gray-8;
        font-size: 14px;
        height: 32px;
      }
      .radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: $gray-9;
        border-color: $gray-4;
        background-color: $gray-4;
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: $gray-4;
      }
      .radio .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
        color: $gray-9;
      }
    }
  }
  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-menu {
    svg, path {
      fill: $pink
    }
    .bn-dropdown__overlay {
      width: 216px;
      background-color: $gray-1;
      padding-bottom: 8px ;
    }
    .dropdown-item {
      &:hover {
        color: $main;
      }
    }
  }
  &-accounts-container {
    display: flex;
    flex-direction: column;
    &__title {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      color: $gray-7;
    }
    &__add {
      cursor: pointer;
      margin-left: 12px;
      width: 16px;
      height: 16px;
    }
    &__show-all {
      padding: 11px;
      width: 100%;

      background: $gray-1;
      cursor: pointer;
      color: $gray-7;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      border: none;
      border-top: 1px solid $gray-3;
      border-radius: 0 0 8px 8px;
      &:hover {
        background: $gray-light;
        text-decoration: underline;
      }
    }
  }
  &-account-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    width: 440px;
    cursor: pointer;
    @include Transition;
    &:hover {
      background-color: $gray-light;
    }
    &__left {
      width: 400px;
      padding: 8px 16px 8px 8px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-bottom: 1px solid $gray-3;
      .hint-content {
        align-self: auto;
      }
    }
    &__icon {
      width: 26px;
      height: 26px;
      svg {
        width: 26px;
        height: 26px;
      }
    }
    &__data {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      text-align: start;
    }
    &__title {
      @include Ellipsis;
      max-width: 172px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    &__sub-title {
      font-size: 14px;
      line-height: 18px;
      color: $gray-7;
      max-width: 133px;
      @include Ellipsis;
    }
    &__balance {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      text-align: end;
    }
    &__cost {
      white-space: nowrap;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      .asset-amount {
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__count {
      font-size: 14px;
      line-height: 18px;
      color: $gray-7;
      white-space: nowrap;
    }
    &__file-input {
      position: absolute;
      width: 0;
      height: 0;
    }
    .clients-detail-menu svg{
      transform: rotate(-90deg);
    }
  }
  &-transactions-wrapper {
    width: 100%;
    &__title {
      padding-left: 8px;
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    &__container {
      border: 1px solid $gray-4;
      border-radius: 8px;
      background: $gray-light;
      overflow: hidden;
    }
    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 392px;
    }
    &__show-all {
      text-align: right;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      color: $main;
      cursor: pointer;
      &:hover {
        color: $blue-2;
        text-decoration-line: underline;
      }
    }
    &__row {
      text-align: right;
      width: 100%;
      margin-top: 16px;
    }
  }
  &-transaction-card {
    width: 100%;
    padding: 9px 16px 9px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $gray-light;
    cursor: pointer;
    &.crypto {
      cursor: default;
    }
    &:not(:first-child) {
      border-top: 1px solid $gray-4;
    }
    &:nth-child(even) {
      background-color: $gray-1;
    }
    &__info {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      text-align: left;
    }
    &__title {
      @include Ellipsis;
      max-width: 212px;
      font-size: 14px;
      line-height: 22px;
    }
    &__date {
      font-size: 12px;
      line-height: 16px;
      color: $gray-7;
    }
    &__balance {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      text-align: right;
    }
    &__main-value {
      font-size: 14px;
      line-height: 22px;
      color: $gray-8;
      white-space: nowrap;
      &.positive {
        color: $pink;
      }
    }
    &__secondary-value {
      font-size: 12px;
      line-height: 16px;
      color: $gray-7;
      white-space: nowrap;
    }
    &__icon {
      &.arrow-up {
        transform: rotate(180deg);
        svg, path {
          fill: $main;
          stroke: $main;
        }
      }
    }
  }
  &-related-crypto {
    position: relative;
    min-height: 456px;
    margin-top: 40px;
    padding: 24px;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 8px;
    &.empty {
      min-height: 200px
    }
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    &-wallets {
      margin-top: 24px;
    }
    &-transactions {
      margin-top: 12px;
      width: 410px;
    }
    &__analyzing {
      position: absolute;
      top: 24px;
      right: 40px;
      font-size: 14px;
      line-height: 24px;
      color: $gray-7;
      &.error {
        color: $red-6;
      }
    }
    &-empty{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &__text {
        margin-top: 8px;
        font-size: 14px;
        line-height: 24px;
        color: $gray-7;
        &.error {
          color: $red-6;
        }
      }
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}