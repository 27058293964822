.sticky {
  position: sticky;
  top: 0;
  z-index: 3;
}

.transfers {
  &-group {
    display: flex;
    flex-direction: column;
    &__title {
      margin-left: 24px;
      margin-bottom: 16px;
      margin-top: 40px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    &__list {
      display: flex;
      flex-direction: column;
      &:first-child {
        margin-bottom: 40px;
      }
    }
  }
  &-header {
    @include Transition;
    &:not(.stickyTop) {
      background: $gray-2;
      border-bottom: 1px solid $gray-4;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    z-index: 3;
    &__content {
      padding: 24px 0;
    }
    &__counter {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray-8;
      &-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: 8px;
      }
    }
  }
  &-filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .select {
      margin-right: 16px;
    }
    .client {
      width: 196px;
    }
    .risk-level {
      width: 140px;
    }
    .operation-type {
      width: 180px;
    }
    .date-picker {
      width: 228px;
    }
    .search {
      margin-left: 16px;
      width: 188px;
    }
    &__btn {
      margin-left: 63px;
      background: inherit;
      color: $main;
      border: none;
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
    }
    &__add-transaction {
      cursor: pointer;
      margin-left: 12px;
      display: flex;
      align-items: center;
      color: $main;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;

      .plus-icon {
        margin-right: 8px;
        width: 22px;
        height: 22px;
      }

      &:hover .plus-icon > path {
        fill: $blue-2;
      }
      &:hover {
        color: $blue-2;
      }

    }
    &__search {
      width: 188px;
      margin-left: auto;
    }
  }
  &-empty {
    margin-top: 200px;
  }
  .clients-show-more {
    margin-top: 0;
  }
  .bn-pagination {
    margin-top: 24px;
  }
  .crypto-related-only {
    margin-left: auto;
  }
}
