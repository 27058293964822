.transaction-details-modal {
  .loading-page {
    height: 344px;
  }
  .default-modal__content {
    min-height: 344px;
    padding: 24px 88px 40px 88px;
  }
  &-content {
    &-crypto {
      padding-top: 24px;
    }
  }
  &-break {
    height: 24px;
    width: 100%;
  }
  &-item {
    max-width: 460px;
    width: 100%;
    min-height: 32px;
    border-bottom: 1px solid $gray-3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__key {
      white-space: nowrap;
      margin-right: 8px;
      padding-left: 24px;
      font-size: 12px;
      line-height: 22px;
      text-transform: uppercase;
      color: $gray-8;
    }
    &__value {
      word-wrap: break-word;
      word-break: break-all;
      text-align: right;
      padding-right: 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      .asset-amount {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  &-tabs {
    position: absolute;
    width: 100%;
    height: 29px;
    top: 76px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $gray-2;
  }
  &-tab {
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    cursor: pointer;
    color: $gray-8;
    @include Transition;
    &.active {
      color: $main;
      border-bottom: 2px solid $main;
    }
    &:first-child {
      margin-right: 40px;
    }
  }
  &-exposure-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
      margin-top: 24px;
    }
    &__top {
      width: 100%;
      height: 32px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
    &__title {
      margin-left: 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      text-transform: uppercase;
    }
    &__risk {
      margin-right: 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
    }
  }
}