.alerts {
  &-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__bottom {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }
  &-filter {
    display: flex;
    align-items: center;
    .select {
      margin-right: 16px;
    }
    &__risk-level {
      width: 140px;
    }
    &__alert-status {
      width: 180px;
    }
    &__alert-type {
      width: 180px;
    }
    &__client {
      width: 180px;
    }
    .date-picker {
      width: 244px;
    }
    &__search {
      width: 336px;
    }
    &__clear {
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      text-decoration-line: underline;
      color: $main;
    }
  }
  &-content {
    width: 100%;
    margin-top: 43px;
  }
  &-title {
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: transparent;
  }
  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 8px;
    width: 100%;
    margin-top: 24px;
    tr:not(.first-child) {
      cursor: pointer;
      @include Transition;
      &:hover {
        background-color: $gray-2
      }
    }
    th {
      padding: 9px 16px 9px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;
      &:first-child {
        padding-left: 28px;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }
    td {
      height: 56px;
      max-height: 56px;
      padding-left: 8px;
      border-bottom: 1px solid $gray-4;
      &:first-child {
        padding-left: 0;
        position: relative;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }
    &__risk-level {
      width: 140px;
      min-width: 140px;
      &__cel {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84px;
        height: 24px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 16px;
        &.low {
          background-color: $gray-1;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23FFA39E' stroke-width='4' stroke-dasharray='10%2c 11' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
      }
    }
    &__client {
      &__id {
        color: $gray-8;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__created-at {
      width: 140px;
      max-width: 140px;
      &__id {
        @include Ellipsis;
        color: $gray-8;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__event-time {
      width: 140px;
    }
    &__status {
      &__assignee-name {
        font-size: 12px;
        line-height: 16px;
        color: $gray-8;
      }
    }
    &__empty {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-show-more {
    margin-top: 8px;
    width: 100%;

    &__btn {
      padding: 20px 0;
      width: 100%;
      background: $gray-2;
      border: 1px solid $gray-4;
      border-radius: 2px 2px 16px 16px;
      color: $main;

      &:hover {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }

      &:focus {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }
    }

    &__skeleton {
      height: 42px !important;
      width: 100% !important;

      border: 0.5px solid $gray-5;
      border-radius: 2px 2px 16px 16px !important;
    }
  }
}