.transaction {
  @include Transition;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 64px;
  background-color: $gray-1;
  border-bottom: 1px solid $gray-4;
  border-left: 1px solid $gray-4;
  border-right: 1px solid $gray-4;
  overflow: hidden;

  & > div {
    @include Transition;
  }
  &.isContinuation {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  &.hasContinuation {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    &:last-child {
      border-bottom: none;
    }
  }
  &:first-child {
    border-top: 1px solid $gray-4;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &-element-model {
    width: 186px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 8px;
    position: relative;
    &__top {
      position: absolute;
      top: 5px;
      left: 8px;
      font-size: 10px;
      line-height: 12px;
      color: $gray-7;
    }
    &.multiple {
      .transaction-element-model__top {
        top: 2px;
      }
    }
    &__icon {
      margin-top: 2px;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
      .crypto-icon {
        width: 24px;
        height: 24px;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
    &__data {
      margin-top: 2px;
      display: flex;
      flex-direction: column;
      &.detailsExists {
        margin-top: 12px;
      }
    }
    &__main {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      .asset-amount {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }
    &__details {
      font-size: 10px;
      line-height: 12px;
      color: $gray-7;
    }
    &-multiple {
      display: flex;
      flex-direction: row;
      margin-top: 22px;
      &__icons {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        margin-right: 12px;
      }
      &__icon {
        width: 24px;
        height: 24px;
        svg {
          width: 24px;
          height: 24px;
        }
        .crypto-icon {
          width: 24px;
          height: 24px;
        }
      }
      &__texts {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
      }
      &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        .asset-amount {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
  &-type {
    width: 254px;
    height: 100%;
    padding-left: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    &__risk-level {
      position: absolute;
      top: 0;
      left: 0;
      height: 64px;
      width: 16px;
      border-right: 1px solid $gray-4;
      &.low {
        background: repeating-linear-gradient(
            45deg,
            $gray-1,
            $gray-1 3px,
            $red-3 3px,
            $red-3 6px
        );
      }
      &.medium {
        background: $red-3;
      }
      &.high {
        background: $red-6;
      }
      &.severe {
        background: $red-7;
      }
    }
    &__icon {
      margin-right: 6px;
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    &__data {
      display: flex;
      flex-direction: column;
    }
    &__type-str {
      font-size: 14px;
      line-height: 18px;
    }
    &__occurred_at {
      font-size: 12px;
      line-height: 16px;
      color: $gray-7;
    }
  }
  &__arrow-icon {
    width: 16px;
    height: 16px;
    transform: rotate(90deg);
    margin-right: 32px;
    margin-left: 20px;
    fill: $gray-8;
    path {
      fill: $gray-8;
    }
  }
  &-additional {
    margin-left: auto;
    padding: 20px 16px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__tag {
      background: $gray-4;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      span {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: $main;
        width: 24px;
      }
      &.exists {
        background-color: $main;
        span {
          color: $gray-4;
        }
      }
    }
    &__notes {
      margin-left: 8px;
      circle {
        fill: $gray-4;
      }
      path {
        fill: $main;
      }

      &.exists {
        circle {
          fill: $main;
        }
        path {
          fill: $gray-4;
        }
      }
    }
  }
  &.in {
    .operation-1 {
      width: 226px;
    }
    .ext-operation {
      width: 407px;
      padding-left: 227px;
      background: linear-gradient(270deg, #F8F8F8 35.87%, #FFEEF6 102.91%);
      &:before {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: -23px;
        left: -23px;
        background-color: $gray-1;
      }
      &:after {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: 40px;
        left: -23px;
        background-color: $gray-1;
      }
      .transaction-element-model__top {
        left: 227px;
      }
    }
    .operation-2, .fiat-operation, .transaction__arrow-icon {
      display: none;
    }
  }
  &.out {
    .operation-1 {
      width: 407px;
      background: linear-gradient(90deg, #F3F4F9 43.56%, #E0E7FE 102.91%);
      &:before {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: -23px;
        right: -23px;
        background-color: $gray-1;
      }
      &:after {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: 40px;
        right: -23px;
        background-color: $gray-1;
      }
    }
    .ext-operation {
      padding-left: 45px;
      width: 226px;
      .transaction-element-model__top {
        left: 45px;
      }
    }
    .operation-2, .fiat-operation, .transaction__arrow-icon {
      display: none;
    }
  }
  &.transfer, &.exchange {
    .transaction__arrow-icon {
      transform: rotate(270deg);
    }
    .fiat-operation, .ext-operation {
      display: none;
    }
  }
  &.fiat_in, &.fiat_out {
    .ext-operation, .operation-2 {
      display: none;
    }
  }
  &.fiat_out {
    .transaction__arrow-icon {
      transform: rotate(270deg);
    }
  }
  .fiat-operation__icon {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $info-block-incognito;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
    }
    svg, path {
      fill: $gray-1;
    }
  }
}