.clients {
  &-filter {
    display: flex;
    align-items: center;
    &__crypto-status {
      width: 220px;
      margin-right: 16px;
    }
    &__type {
      width: 180px;
      margin-right: 16px;
    }
    &__search {
      width: 260px;
      margin-right: 24px;
    }
    &__clear {
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      text-decoration-line: underline;
      color: $main;
    }
  }
  &-content {
    width: 100%;
    margin-top: 40px;
    position: relative;
  }
  &-title {
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: transparent;
  }
  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 8px;
    width: 100%;
    margin-top: 24px;
    tr:first-child {
      margin-bottom: 8px;
    }
    tr:not(.first-child) {
      cursor: pointer;
      @include Transition;
      &:hover {
        background-color: $gray-2
      }
    }
    th {
      padding: 9px 16px 9px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;
      &:first-child {
        width: 40px;
        height: 40px;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }
    td {
      padding: 16px;
      border-bottom: 1px solid $gray-4;
      &:first-child {
        position: relative;
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        //display: flex;
        //align-items: center;
        //justify-content: center;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }

    &__in-process {
      min-width: 100px;
    }
    &__created-at {
      min-width: 100px;
    }
    &__last-activity {
      min-width: 148px;
    }
    &__crypto-status {
      min-width: 150px;
    }
    &__empty {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-add-btn {
    font-size: 14px;
    line-height: 24px;

    &__wrapper {
      position: absolute;
      top: 0;
      right: 0;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &-create-form {
    &__type {
      height: 40px;
      .ant-select-selector {
        height: 38px !important;
      }
      .ant-select-selection-search,
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-top: 4px !important;
      }
      .ant-select-selection-item {
        padding-top: 4px !important;
      }
      .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
        height: 40px;
      }
    }
    &__btn {
      width: 100%;
      height: 40px;
      margin-top: 24px;
    }
  }
  &-show-more {
    margin-top: 8px;
    width: 100%;

    &__btn {
      padding: 20px 0;
      width: 100%;
      background: $gray-2;
      border: 1px solid $gray-4;
      border-radius: 2px 2px 16px 16px;
      color: $main;

      &:hover {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }

      &:focus {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }
    }

    &__skeleton {
      height: 42px !important;
      width: 100% !important;

      border: 0.5px solid $gray-5;
      border-radius: 2px 2px 16px 16px !important;
    }
  }
}