.clients-wallet {
  min-height: 100vh;
  &-info {
    position: relative;
    padding: 32px 40px 40px 40px;
    min-height: 448px;
    background-color: $gray-1;
    &-header {
      &__wrapper {
        display: flex;
        justify-items: center;
        align-items: center;
        width: 100%;
      }
      &__icon {
        width: 40px;
        height: 40px;
        svg {
          width: 40px;
          height: 40px;
        }
      }
      &__data {
        width: 100%;
        margin-left: 16px;
        &-top {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          .address-copy-dropdown {
            margin-left: 8px;
          }
        }
      }
      &__name {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
      }
      &__subname {
        margin-top: 2px;
        color: $gray-8;
        font-size: 16px;
        line-height: 24px;
      }
      &__menu {
        margin-left: 16px;
        path {
          fill: $pink;
        }
        & .bn-dropdown__overlay {
          border-top: 1px solid $gray-4;
          background-color: $gray-1;
        }
        .dropdown-item {
          padding-left: 16px;
        }
      }
      &__status {
        position: relative;
        right: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $gray-8;
        margin-left: auto;
        &:before {
          content:"";
          position: absolute;
          height: 8px;
          width: 8px;
          right: 100%;
          left: -12px;
          background-color: $green-3;
          border-radius: 100%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    &__content {
      display: flex;
      flex-direction: row;
    }
    &-right {
      width: 484px;
      display: flex;
      flex-direction: column;
      .ant-empty {
        margin-top: 100px;
      }
    }
    &-accounts {
      &:first-child {
        margin-top: 40px;
      }
      &:not(:first-child) {
        margin-top: 32px;
      }
      &__title {
        width: 100%;
        margin-bottom: 4px;
        margin-left: 16px;
        font-size: 14px;
        line-height: 22px;
        color: $gray-7;
      }
    }
    &-account-card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      height: 58px;
      width: 400px;
      border-bottom: 1px solid $gray-3;

      .hint-content {
        align-self: auto;
      }
      .clients-detail-account-card__icon {
        width: 26px;
        height: 26px;
        svg {
          width: 26px;
          height: 26px;
        }
      }
      &__data {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        text-align: start;
      }
      &__title {
        @include Ellipsis;
        max-width: 172px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      &__sub-title {
        font-size: 14px;
        line-height: 18px;
        color: $gray-7;
        max-width: 133px;
        @include Ellipsis;
      }
      &__balance {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        text-align: end;
      }
      &__cost {
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        .asset-amount {
          white-space: nowrap;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }
      &__count {
        font-size: 14px;
        line-height: 18px;
        color: $gray-7;
        white-space: nowrap;
      }
    }
    &-balance {
      position: absolute;
      top: 80px;
      right: 40px;
      width: 432px;
      margin-left: auto;
      padding: 16px;
      background-color: $gray-light;
      border-radius: 4px;
      &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 16px 6px 16px;
        border-bottom: 1px solid $gray-3;
        &-title {
          color: $gray-8;
          line-height: 22px;
          text-transform: uppercase;
          font-size: 12px;
        }
        &-value {
          color: $gray-8;
          font-size: 14px;
          line-height: 22px;
          white-space: nowrap;
          b, .asset-amount {
            font-weight: 500;
            color: $gray-9;
            margin-right: 4px;
          }
          display: flex;
          flex-direction: row;

        }
      }
      &__top &__item-title {
        color: $gray-9;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
      }
      &__top &__item-value,&__top .asset-amount {
        color: $gray-9;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      &-divider {
        height: 24px;
        width: 100%;
      }

    }
    &-unknown {
      height: 100%;
      width: 100%;
      position: relative;
      &__content {
        margin-top: 80px;
        margin-left: 56px;
        width: 328px;
        display: flex;
        flex-direction: column;
      }
      &__title {
        margin-bottom: 3px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      &__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 16px;
        color: $gray-8;
      }
      &__link {
        cursor: pointer;
        display: flex;
        align-content: center;
        color: $pink;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
      }
      &__icon {
        margin-top: 4px;
        margin-left: 4px;
        max-width: 16px;
        max-height: 16px;
        transform: rotate(-90deg);
        path {
          fill: $pink;
        }
      }
      &__img {
        position: absolute;
        top: 165px;
        left: 176px;
      }
    }
  }
}