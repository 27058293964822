.aml-risk-settings {
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  &__models {
    width: 100%;
    &__title {
      font-size: 14px;
      line-height: 24px;
      color: $gray-8;
      margin-left: 6px;
      margin-bottom: 4px;
    }
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &__custom {
      margin-top: 40px;
      &__add-btn {
        height: 32px;
        margin-top: 40px;
        margin-left: auto;
        @include TransparentBtn;
      }
    }
  }
  &__model {
    width: 100%;
    height: 80px;
    padding: 0 8px 0 24px;
    border: 1px solid #E8E9F0;
    border-radius: 8px;
    background: $gray-1;
    display: flex;
    align-items: center;
    flex-direction: row;
    &:not(:first-child) {
      margin-top: 16px;
    }
    &__name {
      @include Ellipsis;
      width: 274px;
      margin-right: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    &__rules {
      margin-right: 8px;
      width: 295px;
      a {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: $main;
      }
    }
    &__last-modified {
      margin-right: 8px;
      width: 197px;
      font-size: 14px;
      line-height: 22px;
      color: $gray-7;
    }
    &__status-button {
      width: 83px;
      height: 32px;
      border-radius: 40px;
      font-size: 12px;
      line-height: 16px;
      border: 1px solid $gray-6;

      @include Transition;
      &.active.default-btn.transparent {
        border-color: $green-3;
        color: $green-3;
        cursor: auto;
      }

      &.inactive.default-btn.transparent {
        color: $gray-6;
        &:hover {
          border-color: $pink;
          background-color: $pink;
          color: $gray-1;
        }
      }
    }
    &__menu {
      margin-left: auto;
      transform: rotate(90deg);
      &__trigger {
        display: inline-block;
        width: 100%;
        svg, path {
          fill: $gray-6;
        }
      }
    }
  }
  &__create {
    &__base-model {
      height: 40px;
      border: 1px solid $gray-5;
      &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 37px;
      }
      &.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-top: 2px;;
      }
      & .ant-select-selector {
        height: 40px;
      }
    }
    &__btn {
      height: 40px;
      margin-top: 24px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .aml-risk-settings {
    &__model {
      &__name {
        width: 182px;
      }
      &__rules {
        width: 180px;
      }
      &__last-modified {
        width: 128px;
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .aml-risk-settings {
    padding: 8px 8px 0 8px;
    &__models {
      &__title {
        margin-left: 16px;
      }
      &__custom__add-btn {
        margin: 24px auto;
        width: calc(100% - 16px);
        height: 40px;
        font-size: 14px;
        line-height: 24px;
      }
    }
    &__model__menu__trigger {
      svg, path {
        fill: $gray-8;
      }
    }
    &__model-mobile {
      width: 100%;
      height: 80px;
      padding: 0 12px;
      border: 1px solid $gray-4;
      border-radius: 8px;
      background: $gray-1;
      display: flex;
      align-items: center;
      flex-direction: row;
      &:not(:first-child) {
        margin-top: 8px;
      }
      &__status-switcher {
        margin-right: 16px;
        & .bn-switch .ant-switch.ant-switch-checked {
          background-color: $pink;
        }
      }
      &__naming {
        overflow: hidden;
        margin-right: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      &__name {
        @include Ellipsis;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
      &__last-modified {
        font-size: 14px;
        line-height: 22px;
        color: $gray-7;
      }
      &__rules {
        margin-left: auto;
        font-size: 11px;
        line-height: 16px;
        white-space: nowrap;
      }
    }
  }
}