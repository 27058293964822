.sign-in {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: $gray-1;
  display: flex;
  align-items: center;
  justify-content: center;
  &-content {
    justify-content: center;
    width: 588px;
  }
  &-logo {
    &__cryptoscope, &__by-bitok {
      position: absolute;
      display: flex;
      justify-content: center;
      left: 50%;
      transform: translateX(-50%);
    }
    &__cryptoscope {
      top: -100px;
      bottom: 100%;
    }
    &__by-bitok {
      bottom: -85px;
    }
  }
  &-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 60px;
    padding: 40px 102px 32px 102px;
    background: $gray-3;
    border: 1px solid $gray-4;
    border-radius: 16px;
    &__title {
      margin-bottom: 32px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
  }
  &-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .field-wrapper {
      width: 100%;
    }
    &__btn {
      margin-top: 32px;
      width: 100%;
      height: 40px;
      font-size: 14px;
      line-height: 24px;
    }
  }
  &-lang {
    margin-top: 24px;
  }
  &-imgs {
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    &__okman-big {
      position: absolute;
      top: -250px;
      left: 250px;
      width: 308.43px;
      height: 300px;
    }
    &__okman-small {
      position: absolute;
      left: -650px;
      bottom: -300px;
      width: 195px;
      height: 197px;
    }
  }
}